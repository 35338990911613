<template>
  <div>
    <b-row class="py-4">
      <b-col class="text-right">
        <b-button
          v-if="isWriteAllPermission || isWriteOwnerPermission"
          class="btn btn-success"
          type="button"
          @click="addFile"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm file
        </b-button>
      </b-col>
    </b-row>
    <template-table
      :data="studentReports"
      :column="headers"
      :selectAction="false"
      :tableAction="false"
      :searchAction="false"
      :paging="paging"
    >
      <template v-slot:body="{ item }">
        <td class="cell-overflow">
          <div class="text-overflow">
            {{ item.fileName }}
          </div>
        </td>
        <td class="cell-overflow">
          <div class="text-overflow">
            {{ item.reportName }}
          </div>
        </td>
        <td>
          {{ item.creator }}
        </td>
        <td>{{ convertTimestampToDate(item.createDatetime) }}</td>
        <td class="cell-overflow">
          <div class="text-overflow">
            {{ item.description }}
          </div>
        </td>
        <td>
          <div class="d-flex justify-content-around align-items-center">
            <b-button
              class="btn btn-download"
              size="sm"
              type="button"
              @click="downloadFile(item)"
            >
              <b-icon-download></b-icon-download>
            </b-button>
            <b-button
              class="btn btn-delete ml-2"
              size="sm"
              type="button"
              @click="showPopupConfirm(item)"
            >
              <b-icon-trash></b-icon-trash>
            </b-button>
          </div>
        </td>
      </template>
    </template-table>
    <ModalAddFile
      v-if="showModal"
      :show="showModal"
      @reset="resetModal"
      @reload-data="loadData"
    />
  </div>
</template>
<script>
export default {
  name: 'StudentReport',
  components: {
    ModalAddFile: () => import('../components/ModalAddFile.vue'),
  },
  data() {
    return {
      showModal: false,
      studentReports: [],
      headers: [
        {
          label: 'File',
          class: 'text-left',
          key: 'file',
        },
        {
          label: 'Tên',
          class: 'text-left',
          key: 'name',
        },
        {
          label: 'Người upload',
          class: 'text-left',
          key: 'uploadtor',
          style: {
            width: '150px',
          },
        },
        {
          label: 'Ngày upload',
          class: 'text-left',
          key: 'uploadDate',
          style: {
            width: '120px',
          },
        },
        {
          label: 'Ghi chú',
          class: 'text-left',
          key: 'note',
        },
        {
          label: '',
          class: 'text-left',
          key: 'actions',
          style: {
            width: '10%',
          },
        },
      ],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  computed: {
    patientId() {
      return this.$route.params.id;
    },
    searchParams() {
      return {
        patientId: this.patientId,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    addFile() {
      this.showModal = true;
    },
    resetModal() {
      this.showModal = false;
    },
    async loadData(loading = true) {
      try {
        loading && this.$store.commit('context/setLoading', true);
        const { meta, data, error } = await this.$api.get(
          '/UserDashboard/Patient-Report',
          {
            params: this.searchParams,
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.studentReports = data;
        this.paging.total = meta.total;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        loading && this.$store.commit('context/setLoading', false);
      }
    },
    showPopupConfirm(item) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn xóa ${item.reportName} không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.deleteReport(item.id);
          }
        }.bind(this),
      );
    },
    async deleteReport(id) {
      try {
        this.$store.commit('context/setLoading', true);
        const { meta, error } = await this.$api.delete(
          `/UserDashboard/Patient-Report/${id}`,
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        await this.loadData(false);
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async downloadFile(file) {
      this.$store.commit('context/setLoading', true);
      const link = document.createElement('a');
      link.href = file.virtualFilePath;
      link.download = file.name;
      link.click();
      URL.revokeObjectURL(link.href);
      this.$store.commit('context/setLoading', false);
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-download::v-deep {
  background: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}
.btn-delete::v-deep {
  background: #ffebef !important;
  border-color: #ffebef !important;
  color: #ff5756 !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
